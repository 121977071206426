import { Box, Button, ButtonGroup, Container, Flex, Heading, Spacer, Link } from "@chakra-ui/react";
import { Link as RouterLink, Outlet } from "react-router-dom";

export default function AppContainer() {
  return (
    <Container maxW="container.lg">
      <Flex alignItems='center' mb={8} py={4} borderBottom="1px solid lightgray" >
        <Heading as={RouterLink} to='/' size='lg'>PostCode IMD</Heading>
        <Spacer />
        <ButtonGroup gap='2'>
          <Button as={RouterLink} colorScheme='green' variant="outline" to='/report'>Create My Report</Button>
        </ButtonGroup>
      </Flex>
      <Outlet />
      <Box textAlign='center' mt={8} py={4} borderTop="1px solid lightgray" fontSize='sm' fontStyle='gray.500'>
        <Link isExternal href='http://impactgo.uk'>&copy; CM Russell Limited 2024</Link>&nbsp;|&nbsp;
        <Link isExternal href='http://impactgo.uk/legal'>Privacy & Cookies</Link>
      </Box>
    </Container>
  );
}