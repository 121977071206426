import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Home } from './Home';
import { NotFoundError } from './NotFound';
import { ViewReport } from './Report';
import { NewReport } from './NewReport';
import AppContainer from './AppContainer';

const queryClient = new QueryClient();

const routes = [
  {
    path: '/',
    element: <AppContainer />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/report/', element: <NewReport /> },
      { path: '/report/:slug', element: <ViewReport /> },
      { path: '*', element: <NotFoundError /> }
    ]
  }
];

function App() {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider>
          <RouterProvider router={createBrowserRouter(routes)} />
        </ChakraProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
