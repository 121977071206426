import Joi from "joi";
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import { Alert, AlertIcon, Box, Button, ButtonGroup, Checkbox, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Stack, Textarea, } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useCreateReport } from "./hooks";
import { useState } from "react";

interface FieldTypes {
  email: string;
  postcodes: string[];
}

const schema = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).required(),
  postcodes: Joi.array().min(1).max(10000).items(
    Joi.string().allow(null, '').trim().uppercase().replace(/[^a-zA-Z0-9]/g, '')
  ).required(),
});

export function NewReport() {
  const navigate = useNavigate();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const { mutate, error } = useCreateReport();
  const { register, control, handleSubmit, formState: { errors } } = useForm<FieldTypes>({
    resolver: joiResolver(schema)
  });

  const onSubmit = handleSubmit((data) => {
    // filter out empty postcodes from array
    data.postcodes = data.postcodes.filter((postcode) => postcode.length > 0);

    mutate(data, {
      // Navigate to report page on success
      onSuccess: (data) => navigate(`/report/${data.slug}`),
    });
  });

  return (
    <Stack as="form" spacing={6} onSubmit={onSubmit} maxW='2xl' margin='auto'>
      <Heading size='lg'>New Report</Heading>

      {error ? <Alert status='error'><AlertIcon />{error.message}</Alert> : null}

      <FormControl isInvalid={'email' in errors}>
        <FormLabel htmlFor={'email'}>Email</FormLabel>
        <FormHelperText mb={2}>You'll receive an email when your report is ready</FormHelperText>
        <Input id={'email'} autoComplete='off' {...register('email')} />
        {('email' in errors)
          ? <FormErrorMessage>{errors['email']?.message as string}</FormErrorMessage>
          : null}
      </FormControl>

      <FormControl isInvalid={'postcodes' in errors}>
        <FormLabel htmlFor={'postcodes'}>Postcodes</FormLabel>
        <FormHelperText mb={2}>UK Postcodes for each property in your portfolio. One per line.</FormHelperText>
        <Controller
          name={'postcodes'}
          control={control}
          render={({ field }) => (
            <Textarea
              id='postcodes'
              autoComplete='off'
              rows={10}
              onChange={(ev) => { field.onChange(ev.target.value.split('\n')); }}
              value={!!field.value ? field.value.join('\n') : undefined}
            />
          )}
        />
        {('postcodes' in errors)
          ? <FormErrorMessage>{errors['postcodes']?.message as string}</FormErrorMessage>
          : null}
      </FormControl>

      <FormControl>
        <Checkbox 
          size='lg'
          isChecked={acceptTerms} 
          onChange={() => setAcceptTerms(!acceptTerms)}
        >
          <Box fontSize='sm' color='gray.600'>
            I agree that: this service does not constitute financial advice and is for information purposes only; 
            report data will be automatically deleted after 30 days; 
            and I may be contacted about similar services in the future. Personal data is stored in accordance with our Privacy Policy.
          </Box>
        </Checkbox>
      </FormControl>

      <ButtonGroup spacing="4">
        <Button aria-label='submit' type="submit" colorScheme='blue' isDisabled={!acceptTerms}>Submit Report</Button>
      </ButtonGroup>
    </Stack>
  )
}