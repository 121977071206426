import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Heading, Stack, Text, Link, UnorderedList, ListItem } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

export function Home() {
  return (
    <Stack spacing={6} maxW='2xl' margin='auto'>
      <Heading size='lg'>Hello! 👋</Heading>
      <Text fontSize='xl'>
        Index of Multiple Deprivation is an important measure to understand
        where in the UK support is most needed. But using IMD data is hard.
      </Text>
      <Text fontSize='xl'>
        So I built this tool to make it easier.
      </Text>
      <Text fontSize='xl'>
        If you have a property portfolio, you can use this tool to get a report on the IMD ranks of the areas you own property in.
      </Text>
      <Box textAlign='center'>
        <Button as={RouterLink} colorScheme='green' size="lg" fontSize='1.5rem' p={4} to='/report'>Create My Report</Button>
      </Box>
      <Text fontSize='xl'>
        It's easy to use, just provide a list of postcodes for your properties and I'll email you a full report.
      </Text>
      <Text fontSize='xl'>
        I hope you find it useful!
      </Text>
      <Text fontSize='xl'>
        - Craig ☺️
      </Text>


      <Heading size='md'>Common Questions</Heading>
      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight={500}>What is the Index of Multiple Deprivation?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box mb={4}>
              Index of Multiple Deprivation (IMD) are used to classify the relative deprivation of small areas (typically containing around 1,500 people).
            </Box>
            <Box mb={4}>
              The Wikipedia article contains a good overview.{' '}
              <Link isExternal href="https://en.wikipedia.org/wiki/Multiple_deprivation_index">https://en.wikipedia.org/wiki/Multiple_deprivation_index</Link>
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight={500}>Why is there no IMD for the UK as a whole?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box mb={4}>
              Responsibility for IMD data is devolved to the four nations of the UK (England, Scotland, Wales & Northern Ireland).
              Each nation used a different methodology and data sources to compile their IMD, and publishes these on their own schedules.
            </Box>
            <Box>
              Consequently, the IMD ranks for each nation are not comparable with one another,
              therefore there is no "UK wide" Index of Multiple Deprivation.
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight={500}>What data sources do you use?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box mb={4}>
              This service uses the latest available IMD data for each nation of the UK.
            </Box>
            <UnorderedList mb={4}>
              <ListItem><Link isExternal href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">IMD England 2019</Link></ListItem>
              <ListItem><Link isExternal href="https://www.gov.scot/collections/scottish-index-of-multiple-deprivation-2020/">IMD Scotland 2020</Link></ListItem>
              <ListItem><Link isExternal href="https://statswales.gov.wales/Catalogue/Community-Safety-and-Social-Inclusion/Welsh-Index-of-Multiple-Deprivation">IMD Wales 2019</Link></ListItem>
              <ListItem><Link isExternal href="https://www.nisra.gov.uk/publications/nimdm17-soa-level-results">IMD Northern Ireland 2017</Link></ListItem>
            </UnorderedList>
            <Box mb={4}>
              The scores are produced for "lower-layer super output areas" (LSOAs), which are small areas of around 1,500 people.
              Postcodes are mapped to LSOAs with the data file below.
            </Box>
            <UnorderedList mb={4}>
              <ListItem><Link isExternal href="https://geoportal.statistics.gov.uk/datasets/e7824b1475604212a2325cd373946235/about">Postcode to LSOA May 2022</Link></ListItem>
            </UnorderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight={500}>What do I get in this report?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box mb={4}>
              For each postcode provided, the report will contain the following information:
            </Box>
            <UnorderedList mb={4}>
              <ListItem>Postcode</ListItem>
              <ListItem>LSOA Code</ListItem>
              <ListItem>LSOA Name</ListItem>
              <ListItem>Local Authority Code</ListItem>
              <ListItem>Local Authority Name</ListItem>
              <ListItem>Nation</ListItem>
              <ListItem>England IMD Rank</ListItem>
              <ListItem>England IMD Decile</ListItem>
              <ListItem>Scotland IMD Rank</ListItem>
              <ListItem>Scotland IMD Decile</ListItem>
              <ListItem>Wales IMD Rank</ListItem>
              <ListItem>Wales IMD Decile</ListItem>
              <ListItem>Northern Ireland IMD Rank</ListItem>
              <ListItem>Northern Ireland IMD Decile</ListItem>
            </UnorderedList>
            <Box mb={4}>
              If a postcode cannot be matched to an LSOA, the report will contain a blank row for that postcode.
            </Box>
            <Box mb={4}>
              Additionally, the report will contain a summary of the total number of postcodes in each decile for each nation.
            </Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box as='span' flex='1' textAlign='left' fontWeight={500}>What about my data?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Box mb={4}>
              Your postcode data is only used to generate your report, and is automatically deleted after 30 days.
            </Box>
            <Box mb={4}>
              Your email address is used to send you the report, and is stored in accordance with our Privacy Policy.
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack >
  )
}