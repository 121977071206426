import { Alert, AlertIcon, Card, Stack, Text } from "@chakra-ui/react";
import { type Report } from "./hooks";

export default function ReportDeleted({ report }: { report: Report }) {
  return (
    <>
      <Alert status='error' mb={6}>
        <AlertIcon />
        Report deleted
      </Alert>
      
      <Card as={Stack} p={4} spacing={4}>
        <Text>This report is no longer available. All postcode data has been deleted.</Text>
      </Card>
    </>
  );
}