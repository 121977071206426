import { Alert, AlertIcon, Card, Stack, Text } from "@chakra-ui/react";
import { type Report } from "./hooks";

export default function ReportPending({ report }: { report: Report }) {
  return (
    <>
      <Alert status='info' mb={6}>
        <AlertIcon />
        Report in process.
      </Alert>
      
      <Card as={Stack} p={4} spacing={4}>
        <Text>Your report is being processed. You'll receive an email when it is ready.</Text>
      </Card>
    </>
  );
}