import { Alert, AlertIcon, Button, Card, Heading, Link, SimpleGrid, Stack, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { API_BASE, ReportWithDeciles } from "./hooks";

function DecileTable({ data }: { data: ReportWithDeciles['deciles'] }) {
  const sumPostcodes = (data: ReportWithDeciles['deciles']) => data.reduce((acc, row) => acc + row.countPostcodes, 0);

  const toPerc = (val: number) => {
    const perc = (val / sumAllPostcodes) * 100
    return Math.round(perc * 10) / 10;
  }

  const deciles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const englandDeciles = data.filter((row) => row.nation === 'England');
  const scotlandDeciles = data.filter((row) => row.nation === 'Scotland');
  const walesDeciles = data.filter((row) => row.nation === 'Wales');
  const niDeciles = data.filter((row) => row.nation === 'Northern Ireland');

  const sumAllPostcodes = sumPostcodes(data);
  const sumEnglandPostcodes = sumPostcodes(englandDeciles);
  const sumScotlandPostcodes = sumPostcodes(scotlandDeciles);
  const sumWalesPostcodes = sumPostcodes(walesDeciles);
  const sumNIPostcodes = sumPostcodes(niDeciles);

  return (
    <TableContainer>
      <Table size='sm' variant='simple' mb={4}>
        <Thead>
          <Tr>
            <Th>IMD Decile</Th>
            <Th isNumeric>England</Th>
            <Th isNumeric>Scotland</Th>
            <Th isNumeric>Wales</Th>
            <Th isNumeric>Northern Ireland</Th>
            <Th isNumeric>TOTAL</Th>
            <Th isNumeric>TOTAL %</Th>
          </Tr>
        </Thead>
        <Tbody>
          {deciles.map((d, i) => (
            <Tr key={`row-${d}`}>
              <Td>{d}</Td>
              <Td isNumeric>{englandDeciles[i].countPostcodes}</Td>
              <Td isNumeric>{scotlandDeciles[i].countPostcodes}</Td>
              <Td isNumeric>{walesDeciles[i].countPostcodes}</Td>
              <Td isNumeric>{niDeciles[i].countPostcodes}</Td>
              <Td isNumeric>{englandDeciles[i].countPostcodes + scotlandDeciles[i].countPostcodes + walesDeciles[i].countPostcodes + niDeciles[i].countPostcodes}</Td>
              <Td isNumeric>{toPerc(englandDeciles[i].countPostcodes + scotlandDeciles[i].countPostcodes + walesDeciles[i].countPostcodes + niDeciles[i].countPostcodes)}%</Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot fontWeight='bold'>
          <Tr>
            <Td>TOTAL</Td>
            <Td isNumeric>{sumEnglandPostcodes}</Td>
            <Td isNumeric>{sumScotlandPostcodes}</Td>
            <Td isNumeric>{sumWalesPostcodes}</Td>
            <Td isNumeric>{sumNIPostcodes}</Td>
            <Td isNumeric>{sumAllPostcodes}</Td>
            <Td isNumeric></Td>
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  )
}

export default function ReportCompleted({ report }: { report: ReportWithDeciles }) {
  const downloadUrl = `${API_BASE}/reports/${report.slug}/download`;
  return (
    <>
      <Heading size='lg' mb={6}>Your Postcode IMD Report 🚀</Heading>

      <Alert status='success' mb={6}>
        <AlertIcon />
        Processing <b>&nbsp;{report.countProcessed}&nbsp;</b> postcodes complete!
      </Alert>

      <SimpleGrid columns={{ base: 1, sm: 2 }} spacing={6} mb={6}>
        <Card as={Stack} p={4} spacing={4}>
          <Text>Your postcode IMD report is complete.</Text>
          <Button aria-label="download" as={Link} href={downloadUrl} colorScheme='blue' variant="solid">Download Full Postcode IMD Report</Button>
          <Text>For security, report data will be deleted after 30 days.</Text>
        </Card>

        <Card as={Stack} p={4} spacing={4}>
          <Text>If you have any questions or suggestions, please reach out</Text>
          <Text>Thank you!</Text>
          <Text><Link isExternal href="mailto:hello@impactgo.uk" color='teal.500' fontWeight='bold'>hello@impactgo.uk</Link></Text>
        </Card>
      </SimpleGrid>

      <Card p={4} mb={6}>
        <Heading as='h2' size='md' mb={4}>Postcodes per IMD Decile</Heading>

        <DecileTable data={report.deciles} />


        <Stack spacing={1} fontSize='sm'>
          <Text fontWeight='bold'>NOTES:</Text>
          <Text>Lower number value IMD Deciles are more deprived</Text>
          <Text>Excludes postcodes that cannot be matched to the latest available IMD data</Text>
          <Text>There is no IMD rank for the UK as a whole. Each nation has its own index, which are not comparable</Text>
        </Stack>
      </Card>

      <Stack spacing={2} mb={6} textAlign='center'>
        <Text fontSize='sm' colorScheme='gray.400'>
          Report requested at {new Date(report.createdAt).toLocaleString()}.
          Processed {report.countProcessed} postcodes in {report.duration}ms.
        </Text>
      </Stack>
    </>
  );
}