import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios";

export const API_BASE = process.env.REACT_APP_API_URL || 'http://localhost:1338';

const apiClient = axios.create({
  timeout: 2500 // 2.5 seconds
});

interface Decile {
  nation: string
  decile: number
  countPostcodes: number
  percPostcodes: number
}

export interface Report {
  id: number
  slug: string
  status: string
  email: string
  countProcessed: number
  duration: number
  createdAt: string
  updatedAt: string
  deleteAt: string
}

export interface ReportWithDeciles extends Report {
  deciles: Decile[]
}

export function useCreateReport() {
  const url = `${API_BASE}/reports/`;
  return useMutation<Report, Error, Partial<Report>>({
    mutationFn: async (report) => {
      const { data } = await apiClient.post(url, report);
      return data;
    },
  });
}

export function useGetReport(slug: string) {
  const url = `${API_BASE}/reports/${slug}`;
  return useQuery<ReportWithDeciles, Error>({
    queryKey: ['useGetReport', slug],
    queryFn: async () => {
      const { data } = await apiClient.get(url);
      return data;
    },
  });
}